import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '@helpers/AsyncFunc';
import { useSelector } from 'react-redux';
import { defineUserRoles } from '@helpers/utility';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard'))
  },
  {
    path: 'radius-profiles',
    component: asyncComponent(() => import('@page/radius-profiles')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Corporate Admin', 'Admin', 'Tester']
  },
  {
    path: 'radius-profiles/:id/edit',
    component: asyncComponent(() => import('@page/radius-profiles/addEdit')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Corporate Admin', 'Admin', 'Tester']
  },
  {
    path: 'users',
    component: asyncComponent(() => import('@page/users')),
    roles: ['Admin', 'User Admin', 'Partner Support Admin']
  },
  {
    path: 'users/create',
    component: asyncComponent(() => import('@page/users/addEdit')),
    roles: ['Admin', 'User Admin', 'Partner Support Admin']
  },
  {
    path: 'users/:userId/edit',
    component: asyncComponent(() => import('@page/users/addEdit')),
    roles: ['Admin', 'User Admin', 'Partner Support Admin']
  },
  {
    path: 'plans',
    component: asyncComponent(() => import('@page/plans')),
    roles: ['Admin']
  },
  {
    path: 'plans/create',
    component: asyncComponent(() => import('@page/plans/addEdit')),
    roles: ['Admin']
  },
  {
    path: 'plans/:planId/edit',
    component: asyncComponent(() => import('@page/plans/addEdit')),
    roles: ['Admin']
  },
  {
    path: 'plans/:planId/replicate',
    component: asyncComponent(() => import('@page/plans/addEdit')),
    roles: ['Admin']
  },
  /*
  {
    path: 'plans/promotions',
    component: asyncComponent(() => import('@page/plans/promotions'))
  },
  */
  {
    path: 'groups',
    component: asyncComponent(() => import('@page/groups')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Tester']
  },
  {
    path: 'applications',
    component: asyncComponent(() => import('@page/applications')),
    roles: [
      'FBB Operations',
      'FBB Operations Lead',
      'Customer Service',
      'DIGI Store Staff',
      'DIGI Dealer',
      'GENEX',
      'Admin',
      'DIGI Store Manager',
      'Marketing Ops',
      'Telesales Staff',
      'Telesales Manager',
      'Performance Analyst',
      'Tester'
    ]
  },
  {
    path: 'applications-digi',
    component: asyncComponent(() => import('@page/applications')),
    roles: [
      'Alternate Channel'
    ]
  },
  {
    path: 'applications-celcom',
    component: asyncComponent(() => import('@page/applications')),
    roles: [
      'Alternate Channel'
    ]
  },
  {
    path: 'applications/files',
    component: asyncComponent(() => import('@page/applications/file')),
    roles: [
      'FBB Operations',
      'FBB Operations Lead',
      'Customer Service',
      'DIGI Store Staff',
      'DIGI Dealer',
      'GENEX',
      'Admin',
      'DIGI Store Manager',
      'Marketing Ops',
      'Tester'
    ]
  },
  {
    path: 'applications/create',
    component: asyncComponent(() => import('@page/applications/addEdit')),
    roles: [
      'FBB Operations',
      'FBB Operations Lead',
      'Customer Service',
      'DIGI Store Staff',
      'DIGI Dealer',
      'GENEX',
      'Admin',
      'DIGI Store Manager',
      'Marketing Ops',
      'Telesales Staff',
      'Telesales Manager',
      'Tester',
      'Alternate Channel'
    ]
  },
  {
    path: 'celcom/create',
    component: asyncComponent(() => import('@page/celcom/addEdit')),
    roles: [
      'FBB Operations',
      'FBB Operations Lead',
      'Customer Service',
      'DIGI Store Staff',
      'DIGI Dealer',
      'GENEX',
      'Admin',
      'DIGI Store Manager',
      'Marketing Ops',
      'Telesales Staff',
      'Telesales Manager',
      'Tester',
      'Alternate Channel'
    ]
  },
  {
    path: 'applications/:session/edit',
    component: asyncComponent(() => import('@page/applications/addEdit')),
    roles: [
      'FBB Operations',
      'FBB Operations Lead',
      'Customer Service',
      'DIGI Store Staff',
      'DIGI Dealer',
      'GENEX',
      'Admin',
      'DIGI Store Manager',
      'Marketing Ops',
      'Telesales Staff',
      'Telesales Manager',
      'Performance Analyst',
      'Tester',
      'Alternate Channel'
    ]
  },
  {
    path: 'celcom/:session/edit',
    component: asyncComponent(() => import('@page/celcom/addEdit')),
    roles: [
      'FBB Operations',
      'FBB Operations Lead',
      'Customer Service',
      'DIGI Store Staff',
      'DIGI Dealer',
      'GENEX',
      'Admin',
      'DIGI Store Manager',
      'Marketing Ops',
      'Telesales Staff',
      'Telesales Manager',
      'Performance Analyst',
      'Tester',
      'Alternate Channel'
    ]
  },
  // {
  //   path: 'broadbands',
  //   component: asyncComponent(() => import('@page/broadbands')),
  //   roles: [
  //     'FBB Operations',
  //     // 'Customer Service',
  //     // 'DIGI Store Staff',
  //     // 'DIGI Dealer',
  //     // 'GENEX',
  //     'Admin',
  //     // 'DIGI Store Manager',
  //     'Marketing Ops',
  //     'Performance Analyst',
  //     'Tester'
  //   ]
  // },
  {
    path: 'broadbands/files',
    component: asyncComponent(() => import('@page/broadbands/file')),
    roles: [
      // 'Customer Service',
      // 'DIGI Store Staff',
      // 'DIGI Dealer',
      // 'GENEX',
      'Admin',
      // 'DIGI Store Manager',
      // 'Marketing Ops',
      'Tester'
    ]
  },
  // {
  //   path: 'broadbands/create',
  //   component: asyncComponent(() => import('@page/broadbands/addEdit')),
  //   roles: [
  //     'FBB Operations',
  //     // 'Customer Service',
  //     // 'DIGI Store Staff',
  //     // 'DIGI Dealer',
  //     // 'GENEX',
  //     'Admin',
  //     // 'DIGI Store Manager',
  //     // 'Marketing Ops',
  //     'Tester'
  //   ]
  // },
  // {
  //   path: 'broadbands/:key_id/edit',
  //   component: asyncComponent(() => import('@page/broadbands/addEdit')),
  //   roles: [
  //     'FBB Operations',
  //     // 'Customer Service',
  //     // 'DIGI Store Staff',
  //     // 'DIGI Dealer',
  //     // 'GENEX',
  //     'Admin',
  //     // 'DIGI Store Manager',
  //     'Marketing Ops',
  //     'Tester'
  //   ]
  // },
  {
    path: 'notifications',
    component: asyncComponent(() => import('@page/notifications')),
    roles: ['DIGI Dealer', 'DIGI Store Staff', 'DIGI Store Manager', 'Telesales Staff', 'Telesales Manager', 'Alternate Channel']
  },
  {
    path: 'leads',
    component: asyncComponent(() => import('@page/leads')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Tester']
  },
  {
    path: 'leads/:session/edit',
    component: asyncComponent(() => import('@page/leads/edit')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Tester']
  },
  {
    path: 'leads/:session/convert',
    component: asyncComponent(() => import('@page/leads/convert')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Tester']
  },
  // {
  //   path: 'lead-assignment',
  //   component: asyncComponent(() => import('@page/lead-assignment/addEdit')),
  //   roles: ['FBB Operations', 'FBB Operations Lead', 'Tester']
  // },
  {
    path: 'assign-tasks',
    component: asyncComponent(() => import('@page/lead-assignment/addEdit')),
    roles: ['FBB Operations Lead']
  },
  {
    path: 'unassigned-tasks',
    component: asyncComponent(() => import('@page/lead-assignment/list')),
    roles: ['FBB Operations Lead']
  },
  {
    path: 'task-assign-upload',
    component: asyncComponent(() => import('@page/task-assign-upload/csv-upload')),
    roles: ['FBB Operations Lead']
  },
  {
    path: 'assignment-config',
    component: asyncComponent(() => import('@page/assignment-config/addEdit')),
    roles: ['FBB Operations Lead']
  },
  {
    path: 'tdc-csv',
    component: asyncComponent(() => import('@page/tdc/csv')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin']
  },
  //sim serial number
  {
    path: 'simserialnumbers',
    component: asyncComponent(() => import('@page/simSerialNumbers')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin']
  },
  {
    path: 'simserialnumbers/create',
    component: asyncComponent(() => import('@page/simSerialNumbers/addEdit')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin']
  },
  {
    path: 'appointment-config',
    component: asyncComponent(() => import('@page/settings/appointmentProviderConfig')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops']
  },
  {
    path: 'installers',
    component: asyncComponent(() => import('@page/installers')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops']
  },
  {
    path: 'installers/create',
    component: asyncComponent(() => import('@page/installers/addEdit')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops']
  },
  {
    path: 'installers/:installerId/edit',
    component: asyncComponent(() => import('@page/installers/addEdit')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops']
  },
  {
    path: 'installers/regions',
    component: asyncComponent(() => import('@page/installers/regions')),
    roles: ['FBB Operations', 'FBB Operations Lead', 'Admin', 'Marketing Ops']
  },
  {
    path: 'corporateApplications',
    component: asyncComponent(() => import('@page/corporateApplications')),
    roles: ['Admin', 'Corporate Admin', 'Corporate Dealer', 'Marketing Ops', 'FBB Operations', 'FBB Operations Lead', 'Tester']
  },
  {
    path: 'corporateApplications/create',
    component: asyncComponent(() => import('@page/corporateApplications/addEdit')),
    roles: ['Admin', 'Corporate Admin', 'Corporate Dealer', 'Marketing Ops', 'FBB Operations', 'FBB Operations Lead', 'Tester']
  },
  {
    path: 'corporateApplications/:session/edit',
    component: asyncComponent(() => import('@page/corporateApplications/addEdit')),
    roles: ['Admin', 'Corporate Admin', 'Corporate Dealer', 'Marketing Ops', 'FBB Operations', 'FBB Operations Lead', 'Tester']
  },
  {
    path: 'internet-protocol',
    component: asyncComponent(() => import('@page/internet-protocol')),
    roles: ['Corporate Admin', 'Admin']
  },
  {
    path: 'address-upload',
    component: asyncComponent(() => import('@page/address-upload')),
    roles: ['Wholesales', 'Admin', 'FBB Operations', 'FBB Operations Lead', 'Tester']
  },
  {
    path: 'saf-upload',
    component: asyncComponent(() => import('@page/marketing-ops/saf-upload')),
    roles: ['Admin', 'Marketing Ops']
  },
  {
    path: 'serial-upload',
    component: asyncComponent(() => import('@page/marketing-ops/serial-upload')),
    roles: ['Admin', 'Marketing Ops']
  },
  {
    path: 'reports/:reportId',
    component: asyncComponent(() => import('@page/reports'))
  },
  {
    path: 'promotions-config',
    component: asyncComponent(() => import('@page/promotions-config')),
    roles: ['Admin']
  },
  {
    path: 'promotions-config/create',
    component: asyncComponent(() => import('@page/promotions-config/addEdit')),
    roles: ['Admin']
  },
  {
    path: 'promotions-config/:promotionId/edit',
    component: asyncComponent(() => import('@page/promotions-config/addEdit')),
    roles: ['Admin']
  },
  {
    path: '*',
    component: asyncComponent(() => import('@page/common/404'))
  }
];

function AppRouter(props) {
  const { url, style } = props;
  let userSetting = useSelector((state) => state.Auth.userSetting);
  const userRoles = userSetting.roles || [];
  const userRolesIn = defineUserRoles(userRoles);

  // Filter Route if roles has define and for specific role only
  let allowRoutes = routes.filter((route) => {
    if (!route.roles) {
      return true;
    }
    if (userRolesIn(route.roles)) {
      return true;
    }
    return false;
  });
  return (
    <div style={style}>
      <Switch>
        {allowRoutes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route exact={exact !== false} key={singleRoute.path} path={`${url}/${singleRoute.path}`} {...otherProps} />
          );
        })}
      </Switch>
    </div>
  );
}

export default AppRouter;
